<template>
  <b-card
      class="stockrollback-edit-wrapper"
  >
    <!-- form -->
    <ValidationObserver ref="observer">
    <b-form id="stockrollbackForm" class="edit-form mt-2">
      <b-row>
        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="回滚审批单编号"
              label-for="rollback_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="rollback_no"
                size="sm"
                readonly
                v-model="stockRollback.rollback_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="关联订单分类"
              label-for="order_type"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="order_type"
                size="sm"
                readonly=""
                :value="getCodeLabel('stock_rollback_order_type',stockRollback.order_type)"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="关联订单编号"
              label-for="relational_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="relational_no"
                size="sm"
                readonly
                v-model="stockRollback.relational_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="备注"
              label-for="remark"
              label-size="sm"
              class="mb-1 required"
          >
            <ValidationProvider rules="required" name="备注" #default="{ errors }">
              <b-form-textarea
                  id="remark"
                  v-model="stockRollback.remark"
                  rows="3"
                  max-rows="6"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>

          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="相关附件"
              label-for="attachments"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="stockRollback.loaded" theme="files"
                               :readonly="readonly"
                               attachment_id="attachments"
                               :id="stockRollback.attachments"
                               object_type="purchase_refund"
                               :object_id="stockRollback.rollback_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            class="mt-50"
            v-if="!readonly"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save(1)"
          >
            保存
          </b-button>
          <b-button
              variant="primary"
              class="mr-1"
              @click="save(2)"
          >
            保存并提交
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </ValidationObserver>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted, onUnmounted, reactive, ref, toRefs } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import stockrollbackStore from './stockrollbackStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

export default {
  components: {
    AttachmentUpload,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  props: {
    relation: Object,
    rollbackId: {
      type: Number,
      default: 0
    },
    orderType:{
      type: Number,
      default: 1
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const {
      relation,
      rollbackId,
      orderType
    } = props

    // Register module
    if (!store.hasModule('stockrollback')) store.registerModule('stockrollback', stockrollbackStore)

    const state = reactive({
      stockRollback: {},

      //refs
      observer: undefined,
    })

    if (relation) {
      state.stockRollback.order_type = orderType
      state.stockRollback.loaded = true
      state.stockRollback.relational_id = relation.id
      switch (orderType){
        case 1:
          state.stockRollback.relational_no = relation.inbound_no
          break;
        case 2:
          state.stockRollback.relational_no = relation.outbound_no
          break;
      }

    }

    const validate = async () => {
      const success = await state.observer.validate()
      if (success) {
        return true
      } else {
        toast.error('请正确填写')
        return false
      }
    }

    const methods = {
      edit() {
        store.dispatch('stockrollback/edit', { id: rollbackId })
            .then(res => {
              state.stockRollback = res.data.data
            })
      },
      async save(status) {
        const success = await validate()
        if (success) {
          state.stockRollback.status = status
          store.dispatch('stockrollback/save', state.stockRollback)
              .then(res => {
                if (res.data.code === 0) {
                  toast.success(res.data.data)
                  emit('closeModal')
                  if (rollbackId) {
                    emit('refresh')
                  }
                } else {
                  toast.error(res.data.data)
                }
              })
        }
      },
      onUploaded(id, attachment, result) {
        state.stockRollback[id] = result
      },

    }

    onMounted(() => {
      if (rollbackId) {
        methods.edit()
      }
    })

    return {
      ...toRefs(state),
      ...methods,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
